import { Fieldset } from "primereact/fieldset";
import { Calendar } from "primereact/calendar";
import { useState, useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { UnauthenticatedComponent } from "../atoms/UnauthenticatedComponent";
import { useTranslation } from "react-i18next";
import { UpdateLcpDialog } from "../organisms/UpdateLcpDialog";
import {
  addSequenceNumberToEachItemInCollection,
  convertToDashYYYYMMDD,
  dateComparision,
  getPaginationRows,
  selectAllCheckboxVisible,
  sortDateInDataTable,
  userHaveWriteAccessOnSelectedCompound,
} from "../../util/Utility";
import { Toast } from "primereact/toast";
import {
  downloadFile,
  getCallWithoutReducer,
} from "../../actions/performAction";
import { useDispatch } from "react-redux";
import {
  API_DOWNLOAD_LCP_EXCEL,
  API_GET_LCP_DETAILS,
} from "../../actions/ApiConstants";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import {
  APPLICATION_FUNCTION_LCP,
  LOAD_COMPLETE,
  IN_STOCK,
  DIALOG_BOX_LOAD_COMPLETE,
  CALENDAR_FORMAT,
  BANNER_LIFE_TIME,
  PENDING,
} from "../../util/Constants";
import { UploadExcelDialog } from "../organisms/UploadExcelDialog";
import { FilterMatchMode } from "primereact/api";
import "./LCP.css";
import { isAutheticated } from "../../util/AuthService";

export const Lcp = () => {
  const [searchCriteria, setSearchCriteria] = useState({
    etaFromDate: "",
    etaToDate: "",
    status: IN_STOCK,
  });

  useEffect(() => {
    setWriteAccess(userHaveWriteAccessOnSelectedCompound());

    dispatch(
      getCallWithoutReducer(
        API_GET_LCP_DETAILS,
        searchCriteria,
        addSequenceToLcpResult,
        onFailure
      )
    );
  }, []);

  const { t } = useTranslation();
  const toast = useRef(null);
  const [etaFromDate, setEtaFromDate] = useState();
  const [etaToDate, setEtaToDate] = useState();
  const [selectedStatus, setSelectedStatus] = useState(IN_STOCK);
  const [updateLcpDialogVisible, setUpdateLcpDialogVisible] = useState(false);
  const [selectedLcpData, setSelectedLcpData] = useState([]);
  const [lcpResult, setLcpResult] = useState([]);
  const [writeAccess, setWriteAccess] = useState(false);
  const [importExportDialogVisible, setImportExportDialogVisible] =
    useState(false);
  const dispatch = useDispatch();

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    vin: { value: null, matchMode: FilterMatchMode.CONTAINS },
    model: { value: null, matchMode: FilterMatchMode.CONTAINS },
    colour: { value: null, matchMode: FilterMatchMode.CONTAINS },
    destination: { value: null, matchMode: FilterMatchMode.CONTAINS },
    tmeTransportId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    externalId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    lpCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    licensePlate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    eta: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const [errorInEtaToDate, setErrorInEtaToDate] = useState({
    error: false,
    message: "",
  });

  const statusList = [
    { label: "IN STOCK", value: IN_STOCK },
    { label: "LOAD COMPLETE", value: LOAD_COMPLETE },
    { label: "ALL", value: "ALL" },
  ];

  const addSequenceToLcpResult = (data) => {
    setLcpResult(addSequenceNumberToEachItemInCollection(data));
  };

  const getBackgroundForStatusCol = (rowData) => {
    if (rowData) {
      if (rowData.status === LOAD_COMPLETE) {
        return <div className="lcpBackground">{rowData.status}</div>;
      } else if (rowData.status === IN_STOCK) {
        return <div className="instockBackground">{rowData.status}</div>;
      } else if (rowData.status === PENDING) {
        return <div className="pendingBackground">{rowData.status}</div>;
      }
    }
  };

  const showToastWhenNoLcpDataSelected = () => {
    toast.current.show({
      severity: "warn",
      summary: "No Row Selected",
      detail: "Please select atleast one row.",
      life: BANNER_LIFE_TIME,
    });
  };

  const onClickUpdateLcpButton = () => {
    if (selectedLcpData.length === 0) {
      showToastWhenNoLcpDataSelected();
      return;
    }
    setUpdateLcpDialogVisible(true);
  };

  const onClickSearch = () => {
    var flag = true;
    // var transportIdArr = [];
    // var vinArr = [];

    if ((etaToDate && !etaFromDate) || (!etaToDate && etaFromDate)) {
      toast.current.show({
        severity: "warn",
        summary: "Only one ETA date selected",
        detail: "Please select both ETA from and ETA to Date",
        life: BANNER_LIFE_TIME,
      });
      flag = false;
    }

    if (etaToDate && etaFromDate) {
      if (dateComparision(etaToDate, etaFromDate)) {
        setErrorInEtaToDate({
          error: true,
          message: "ETA To date must be greater then ETA From date.",
        });
        flag = false;
      }
    }

    // if (transportIds) {
    //   transportIdArr = transportIds.split(",");
    //   transportIdArr.forEach((id) => {
    //     if (!isValidAlphaNumeric(id) || id.trim.length != 11) {
    //       setErrorInTransportId(true);
    //       flag = false;
    //     }
    //   });
    // }

    // if (VINs) {
    //   vinArr = VINs.split(",");
    //   vinArr.forEach((vin) => {
    //     if (!isValidAlphaNumeric(vin) || vin.trim.length != 17) {
    //       setErrorInVin(true);
    //       flag = false;
    //     }
    //   });
    // }
    if (flag) {
      let searchCriteria = {
        etaFromDate: etaFromDate ? convertToDashYYYYMMDD(etaFromDate) : "",
        etaToDate: etaToDate ? convertToDashYYYYMMDD(etaToDate) : "",
        // transportIds: transportIdArr.length > 0 ? transportIdArr : [],
        // vins: vinArr.length > 0 ? vinArr : [],
        status: selectedStatus ? selectedStatus : IN_STOCK,
      };

      setSearchCriteria(searchCriteria);

      dispatch(
        getCallWithoutReducer(
          API_GET_LCP_DETAILS,
          searchCriteria,
          addSequenceToLcpResult,
          onFailure
        )
      );
    }
  };

  const onFailure = (error) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: error,
      life: BANNER_LIFE_TIME,
    });
  };

  const OnClickReset = () => {
    setEtaFromDate();
    setEtaToDate();
    // setTransportIds("");
    // setVINs("");
    setSelectedStatus(null);
  };

  const setMinDate = () => {
    var date = new Date();
    date.setDate(date.getDate() - 365);
    return date;
  };

  const setMaxDate = () => {
    var date = new Date();
    date.setDate(date.getDate() + 91);
    return date;
  };

  const selectLcpData = (e) => {
    if (e.type === "all" && selectedLcpData.length === 0) {
      let onlyLcpData = [];
      e.value.map((obj) =>
        obj.status === IN_STOCK ? onlyLcpData.push(obj) : ""
      );
      setSelectedLcpData(onlyLcpData);
      return;
    }

    if (e.type === "all" && selectedLcpData.length > 0) {
      setSelectedLcpData([]);
      return;
    }

    if (e.type === "checkbox") {
      let onlyLcpData = [];

      if (selectedLcpData.length === 0 && e.value[0].status === IN_STOCK) {
        setSelectedLcpData(e.value);
        return;
      }

      if (selectedLcpData.length > 0) {
        e.value.map((obj) =>
          obj.status === IN_STOCK ? onlyLcpData.push(obj) : ""
        );
        setSelectedLcpData(onlyLcpData);
        return;
      }
    }
  };

  const onClickExportToExcel = () => {
    dispatch(
      downloadFile(
        API_DOWNLOAD_LCP_EXCEL,
        searchCriteria,
        APPLICATION_FUNCTION_LCP,
        null,
        onFailure
      )
    );
  };

  const setLoadCompleteResultTableHeader = () => {
    return (
      <div>
        <div className="inline"> {t("label.lcpResult")} </div>

        <div
          className="flex gap-2"
          style={{ float: "right", marginTop: "-8px" }}
        >
          {/* <div
            className="inline cursor-pointer export-to-excel-icon"
            data-pr-tooltip="Download table data as Excel"
            data-pr-position="left"
          >
            <Button
              icon="pi pi-file-excel"
              size="small"
              className="excel-download-button"
              onClick={onClickExportToExcel}
              disabled={lcpResult && lcpResult?.length === 0}
            />
            <Tooltip target=".export-to-excel-icon" />
          </div> */}

          <div className="">
            <span
              className="cursor-pointer upload-icon"
              data-pr-tooltip="Upload data as excel"
              data-pr-position="left"
            >
              <Button
                icon="pi pi-upload"
                size="small"
                className="excel-upload-button"
                onClick={() =>
                  setImportExportDialogVisible(!importExportDialogVisible)
                }
              />
            </span>
            <Tooltip target=".upload-icon" />
          </div>
          <div className="inline">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Keyword Search"
              />
            </span>
          </div>
        </div>
      </div>
    );
  };

  const refreshLcpData = () => {
    dispatch(
      getCallWithoutReducer(
        API_GET_LCP_DETAILS,
        searchCriteria,
        addSequenceToLcpResult,
        onFailure
      )
    );
  };

  const onSuccessSaveUpdateLcp = () => {
    toast.current.show({
      severity: "success",
      summary: "Updated",
      detail: "Load Complete Information Updated.",
      life: BANNER_LIFE_TIME,
    });
    setUpdateLcpDialogVisible(false);
    setSelectedLcpData([]);
    refreshLcpData();
  };

  return (
    <>
      {/* <UnauthenticatedTemplate> */}
      {!isAutheticated() && <UnauthenticatedComponent />}
      {/* </UnauthenticatedTemplate> */}

      {/* <AuthenticatedTemplate> */}
      {isAutheticated() && (
        <>
          <Toast ref={toast} position="top-center" />

          <Fieldset legend={t("label.lcpSearch")}>
            <div className="grid">
              <div className="md:col-3 col-12 mt-3">
                <span className="p-float-label md:w-7">
                  <Calendar
                    inputId="etaFromDate"
                    value={etaFromDate}
                    dateFormat={CALENDAR_FORMAT}
                    onChange={(e) => {
                      setEtaFromDate(e.value);
                      setErrorInEtaToDate({ error: false, message: "" });
                    }}
                    minDate={setMinDate()}
                    maxDate={setMaxDate()}
                    showIcon
                  />
                  <label htmlFor="etaFromDate">{t("label.etaFromDate")}</label>
                </span>
              </div>

              <div className="md:col-3 col-12 mt-3">
                <span className="p-float-label md:w-7">
                  <Calendar
                    inputId="etaToDate"
                    value={etaToDate}
                    dateFormat={CALENDAR_FORMAT}
                    onChange={(e) => {
                      setEtaToDate(e.value);
                      setErrorInEtaToDate({ error: false, message: "" });
                    }}
                    minDate={setMinDate()}
                    maxDate={setMaxDate()}
                    showIcon
                  />
                  <label htmlFor="etaToDate">{t("label.etaToDate")}</label>
                </span>
                <span
                  className="p-error block pt-1"
                  style={{
                    visibility: `${errorInEtaToDate.error ? "" : "hidden"}`,
                  }}
                >
                  {errorInEtaToDate.message}
                </span>
              </div>
              {/* 
              <div className="md:col-2 col-8 mt-3">
                <span className="p-float-label">
                  <InputTextarea
                    id="transportIds"
                    value={transportIds}
                    onChange={(e) => {
                      setTransportIds(e.target.value);
                      setErrorInTransportId(false);
                    }}
                    autoResize
                    rows={1}
                    cols={1}
                    className={`md:w-7 w-full  ${
                      errorInTransportId ? "p-invalid" : ""
                    }`}
                  />
                  <label htmlFor="transportIds">
                    {t("label.tarnsportIds")}
                  </label>
                </span>
                <span
                  className="p-error block pt-1"
                  style={{ visibility: `${errorInTransportId ? "" : "hidden"}`  }}>
                  Invalid Transport Ids
                </span>
              </div>

              <div className="md:col-2 col-8 mt-3">
                <span className="p-float-label ">
                  <InputTextarea
                    id="VINS"
                    value={VINs}
                    onChange={(e) => {
                      setVINs(e.target.value);
                      setErrorInVin(false);
                    }}
                    autoResize
                    rows={1}
                    cols={1}
                    className={`md:w-7 w-full ${errorInVin ? "p-invalid" : ""}`}
                  />
                  <label htmlFor="vins">{t("label.vins")}</label>
                </span>
                <span
                  className="p-error block pt-1"
                  style={{ visibility: `${errorInVin ? "" : "hidden"}` }}>
                  Invalid VINs
                </span>
              </div> */}

              <div className="md:col-3 col-12 mt-3">
                <span className="p-float-label md:w-7">
                  <Dropdown
                    inputId="status"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.value)}
                    options={statusList}
                    optionLabel="label"
                    className="w-full"
                    showClear
                  />
                  <label htmlFor="status">{t("label.lcpStatus")}</label>
                </span>
              </div>

              <div className="flex md:col-3 col-12 justify-content-end mt-3">
                <div className="mr-3">
                  <Button
                    label={t("button.search")}
                    icon="pi pi-search"
                    severity="primary"
                    onClick={onClickSearch}
                  />
                </div>
                <div className="mr-3">
                  <Button
                    label={t("button.reset")}
                    icon="pi pi-refresh"
                    severity="primary"
                    onClick={OnClickReset}
                    outlined
                  />
                </div>
              </div>
            </div>
          </Fieldset>
          <br></br>

          <div className="flex flex-column overflow-auto">
            <div className="lcpTableClass">
              <DataTable
                value={lcpResult}
                scrollable
                scrollHeight="400px"
                header={setLoadCompleteResultTableHeader}
                stripedRows
                tableStyle={{ minWidth: "50rem" }}
                selectionMode="checkbox"
                selection={selectedLcpData}
                onSelectionChange={(e) => selectLcpData(e)}
                dataKey="sequenceNumber"
                metaKeySelection={false}
                paginator
                rows={20}
                rowsPerPageOptions={getPaginationRows()}
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                dragSelection
                removableSort
                showGridlines
                filters={filters}
                globalFilterFields={[
                  "vin",
                  "model",
                  "colour",
                  "destination",
                  "externalId",
                  "lpCode",
                  "licensePlate",
                  "eta",
                  "status",
                ]}
                emptyMessage="No data found"
                showSelectionElement={(e) => {
                  if (e.status === LOAD_COMPLETE || e.status === PENDING) {
                    return "";
                  } else return true;
                }}
                showSelectAll={selectAllCheckboxVisible(lcpResult)}
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: "3rem" }}
                ></Column>

                <Column
                  field="vin"
                  header={t("label.vin")}
                  filter
                  filterPlaceholder="Search by VIN"
                  sortable
                ></Column>

                <Column
                  field="model"
                  filter
                  filterPlaceholder="Search by Model"
                  header={t("label.model")}
                  sortable
                ></Column>

                <Column
                  field="color"
                  filter
                  filterPlaceholder="Search by Color"
                  header={t("label.colour")}
                  sortable
                ></Column>

                <Column
                  field="destination"
                  filter
                  filterPlaceholder="Search by Destination"
                  header={t("label.destination")}
                  sortable
                ></Column>
                {/* 
              <Column
                field="tmeTransportId"
                header={t("label.tmeTransportId")}
                sortable
              ></Column> */}

                <Column
                  field="externalId"
                  filter
                  header={t("label.externalId")}
                  sortable
                ></Column>

                <Column
                  field="lpCode"
                  filter
                  filterPlaceholder="Search by LP"
                  header={t("label.lpCode")}
                  sortable
                ></Column>

                <Column
                  field="licensePlate"
                  filter
                  filterPlaceholder="Search by Licence plate"
                  header={t("label.licensePlate")}
                  sortable
                ></Column>

                <Column
                  field="eta"
                  filter
                  filterPlaceholder="Search by ETA"
                  header={t("label.eta")}
                  sortable
                  sortFunction={(e) => sortDateInDataTable(e, "eta")}
                ></Column>

                <Column
                  field="status"
                  filter
                  filterPlaceholder="Search by Status"
                  header={t("label.lcpStatus")}
                  sortable
                  body={getBackgroundForStatusCol}
                ></Column>
              </DataTable>
            </div>

            {writeAccess && (
              <div className="flex justify-content-end mt-3">
                <div className="mr-3">
                  <Button
                    label={t("button.updateLcp")}
                    severity="primary"
                    icon="pi pi-pencil"
                    onClick={() => onClickUpdateLcpButton()}
                    disabled={lcpResult && lcpResult?.length === 0}
                  />
                </div>
              </div>
            )}
            <br />
          </div>

          {updateLcpDialogVisible && (
            <UpdateLcpDialog
              updateLcpDialogVisible={updateLcpDialogVisible}
              setUpdateLcpDialogVisible={setUpdateLcpDialogVisible}
              selectedLcpData={selectedLcpData}
              onSuccessSaveUpdateLcp={onSuccessSaveUpdateLcp}
            />
          )}

          <UploadExcelDialog
            dialogBoxFor={DIALOG_BOX_LOAD_COMPLETE}
            importExportDialogVisible={importExportDialogVisible}
            setImportExportDialogVisible={setImportExportDialogVisible}
            searchCriteria={searchCriteria}
            refreshData={refreshLcpData}
            shouldExportToExcelDisabled={lcpResult && lcpResult?.length === 0}
          />

          <Toast ref={toast} position="top-center" />
        </>
      )}
      {/* </AuthenticatedTemplate> */}
    </>
  );
};
