import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { Calendar } from "primereact/calendar";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  API_GET_TRUCKING_COMPANY_BY_COMPOUND,
  API_POST_UPDATE_LCP,
} from "../../actions/ApiConstants";
import {
  convertToYYYYMMDDWithoutSlash,
  convertToHHmmss,
} from "../../util/Utility";
import { Toast } from "primereact/toast";
import {
  getCallWithoutReducer,
  postCallWithoutReducer,
} from "../../actions/performAction";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { BANNER_LIFE_TIME, RAIL, TRUCK } from "../../util/Constants";
import { isValidAlphaNumeric } from "../../util/CommonValidations";
import { RadioButton } from "primereact/radiobutton";

export const UpdateLcpDialog = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [lcpDate, setLcpDate] = useState(new Date());
  const [lcpTime, setLcpTime] = useState(new Date());
  const [destinationEta, setDestinationEta] = useState(new Date());
  const transportMethodTypes = [TRUCK, RAIL];
  const [transportMethod, setTransportMethod] = useState(
    transportMethodTypes[0]
  );
  const [carrierId, setCarrierId] = useState("");
  const [wagonReferenceNumber, setWagonReferenceNumber] = useState("");
  const [truckPlate, setTruckPlate] = useState("");
  const [trailerPlate, setTrailerPlate] = useState("");
  const [truckingCompany, setTruckingCompany] = useState(null);
  const [truckingCompanies, setTruckingCompanies] = useState([]);

  useEffect(() => {
    dispatch(
      getCallWithoutReducer(
        API_GET_TRUCKING_COMPANY_BY_COMPOUND,
        "",
        setTruckingCompanies,
        onFailure
      )
    );
  }, []);

  // Error States
  const [errorLoadCompleteDate, setErrorInLoadCompleteDate] = useState({
    error: false,
    message: "",
  });

  const [errorInLoadCompleteTime, setErrorInLoadCompleteTime] = useState({
    error: false,
    message: "",
  });

  const [errorInDestinationEta, setErrorInDestinationEta] = useState({
    error: false,
    message: "",
  });

  const [errorInWagonReferenceNumber, setErrorInWagonReferenceNumber] =
    useState({
      error: false,
      message: "",
    });

  const [errorInTruckPlate, setErrorInTruckPlate] = useState({
    error: false,
    message: "",
  });

  const [errorInTrailerPlate, setErrorInTrailerPlate] = useState({
    error: false,
    message: "",
  });

  const [errorInCarrierId, setErrorInCarrierId] = useState({
    error: false,
    message: "",
  });

  const [errorInTruckingCompany, setErrorInTruckingCompany] = useState({
    error: false,
    message: "",
  });

  const onClickConfirm = () => {
    let flag = false;

    if (!lcpDate) {
      setErrorInLoadCompleteDate({
        error: true,
        message: "Please select LCP Date",
      });
      flag = true;
    }

    if (!lcpTime) {
      setErrorInLoadCompleteTime({
        error: true,
        message: "Please select LCP Time",
      });
      flag = true;
    }

    if (!destinationEta) {
      setErrorInDestinationEta({
        error: true,
        message: "Please select Destination ETA",
      });
      flag = true;
    }

    if (transportMethod === TRUCK) {
      if (!truckPlate) {
        setErrorInTruckPlate({
          error: true,
          message: "Truck Plate cannot be empty",
        });
        flag = true;
      }
      if (truckPlate && !isValidAlphaNumeric(truckPlate)) {
        setErrorInTruckPlate({
          error: true,
          message: "Only alphanumric characters are allowed",
        });
        flag = true;
      }

      if (!trailerPlate) {
        setErrorInTrailerPlate({
          error: true,
          message: "Trailer Plate cannot be empty",
        });
        flag = true;
      }
      if (trailerPlate && !isValidAlphaNumeric(trailerPlate)) {
        setErrorInTrailerPlate({
          error: true,
          message: "Only alphanumric characters are allowed",
        });
        flag = true;
      }
    }

    if (transportMethod === RAIL) {
      if (!wagonReferenceNumber) {
        setErrorInWagonReferenceNumber({
          error: true,
          message: "Wagon Reference Number cannot be empty",
        });
        flag = true;
      }
      if (wagonReferenceNumber && !isValidAlphaNumeric(wagonReferenceNumber)) {
        setErrorInWagonReferenceNumber({
          error: true,
          message: "Only alphanumric characters are allowed",
        });
        flag = true;
      }
    }

    if (!carrierId) {
      setErrorInCarrierId({ error: true, message: "Please enter carrier ID" });
      flag = true;
    }
    if (carrierId && !isValidAlphaNumeric(carrierId)) {
      setErrorInCarrierId({
        error: true,
        message: "Only alphanumric characters are allowed",
      });
      flag = true;
    }

    if (!truckingCompany) {
      setErrorInTruckingCompany({
        error: true,
        message: "Please select trucking Company",
      });
      flag = true;
    }

    if (!flag) {
      let vinDestinationMap = new Map();
      props.selectedLcpData.map((data) =>
        vinDestinationMap.set(data.vin, data.destination)
      );

      let updateLcpDto = {
        loadCompleteDate: convertToYYYYMMDDWithoutSlash(lcpDate),
        loadCompleteTime: convertToHHmmss(lcpTime),
        destinationEta: convertToYYYYMMDDWithoutSlash(destinationEta),
        transportMethod: transportMethod,
        carrierId: carrierId,
        licencePlate:
          transportMethod === TRUCK ? truckPlate + trailerPlate : null,
        wagonReferenceNumber:
          transportMethod === RAIL ? wagonReferenceNumber : null,
        truckingCompany: truckingCompany,
        vinDestinationMap: Object.fromEntries(vinDestinationMap), // Convert Map to JSON object
      };

      dispatch(
        postCallWithoutReducer(
          API_POST_UPDATE_LCP,
          updateLcpDto,
          props.onSuccessSaveUpdateLcp,
          onFailure
        )
      );
    }
  };
  const onFailure = (error) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: error,
      life: BANNER_LIFE_TIME,
    });
  };

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      {t("label.lcpConfirmation")}
    </div>
  );

  const footerContent = (
    <div className='grid'>
      <div className='sm:col-12 md:col-8 text-primary'
        style={{ textAlign: 'left' }}
      >
        {t("message.timeZoneMessageForUpdatelcp")}
      </div>
      <div className='sm:col-12 md:col-4 flex justify-content-end' >
        <div>
          <Button
            label={t("button.confirm")}
            icon="pi pi-check"
            size="small"
            onClick={onClickConfirm}
          />
        </div>

        <div>
          <Button
            label={t("button.cancel")}
            icon="pi pi-times"
            onClick={() => props.setUpdateLcpDialogVisible(false)}
            size="small"
          />
        </div>
      </div>
    </div>
  );

  const onTransportMethodChange = (value) => {
    setTransportMethod(value);
    setWagonReferenceNumber("");
    setTrailerPlate("");
    setTruckPlate("");
    setErrorInTrailerPlate({ error: false, message: "" });
    setErrorInWagonReferenceNumber({ error: false, message: "" });
    setErrorInTruckPlate({ error: false, message: "" });
  };

  return (
    <>
      <Toast ref={toast} position="top-center" />
      <Dialog
        visible={props.updateLcpDialogVisible}
        modal
        header={headerElement}
        footer={footerContent}
        onHide={() => props.setUpdateLcpDialogVisible(false)}
        closable
        style={{ maxWidth: "600px" }}
        breakpoints={{
          "2000px": "40rem",
          "1000px": "50rem",
          "800px": "40rem",
          "667px": "30rem",
        }}
      >
        <div className="grid justify-content-center gap-3 m-2">
          <div className="col-12 md:w-7">
            <span className="p-float-label">
              <Calendar
                inputId="lcpDate"
                value={lcpDate}
                onChange={(e) => {
                  setLcpDate(e.value);
                  setErrorInLoadCompleteDate({ error: false, message: "" });
                }}
                className={`w-full ${errorLoadCompleteDate.error ? "p-invalid" : ""
                  }`}
                maxDate={new Date()}
                dateFormat="dd/mm/yy"
                showIcon
              />
              <label htmlFor="lcpDate">
                {t("label.lcpDate")} <b className="text-primary">*</b>
              </label>
            </span>
            <span
              className="p-error block pt-1"
              style={{
                visibility: `${errorLoadCompleteDate.error ? "" : "hidden"}`,
              }}
            >
              {errorLoadCompleteDate.message}
            </span>
          </div>

          <div className="col-12 md:w-7">
            <span className="p-float-label">
              <Calendar
                inputId="time"
                value={lcpTime}
                onChange={(e) => {
                  setLcpTime(e.value);
                  setErrorInLoadCompleteTime({ error: false, message: "" });
                }}
                className={`w-full ${errorInLoadCompleteTime.error ? "p-invalid" : ""
                  }`}
                maxDate={new Date()}
                showIcon
                timeOnly
                dateFormat="hh:mm:ss"
                icon={() => <i className="pi pi-clock" />}
              />
              <label htmlFor="time">
                {t("label.lcpTime")} <b className="text-primary">*</b>
              </label>
            </span>
            <span
              className="p-error block pt-1"
              style={{
                visibility: `${errorInLoadCompleteTime.error ? "" : "hidden"}`,
              }}
            >
              {errorInLoadCompleteTime.message}
            </span>
          </div>

          <div className="col-12 md:w-7">
            <span className="p-float-label">
              <Calendar
                inputId="destinationEta"
                value={destinationEta}
                onChange={(e) => {
                  setDestinationEta(e.value);
                  setErrorInDestinationEta({ error: false, message: "" });
                }}
                className={`w-full ${errorInDestinationEta.error ? "p-invalid" : ""
                  }`}
                dateFormat="dd/mm/yy"
                showIcon
              />
              <label htmlFor="destinationEta">
                {t("label.destinationEta")} <b className="text-primary">*</b>
              </label>
            </span>

            <span
              className="p-error block pt-1"
              style={{
                visibility: `${errorInDestinationEta.error ? "" : "hidden"}`,
              }}
            >
              {errorInDestinationEta.message}
            </span>
          </div>

          <div className=" mb-2">
            <label htmlFor="transportMethod" className=" mr-3">
              {t("label.transportMethod")}: <b className="text-primary">*</b>
            </label>

            <RadioButton
              inputId="transportMethod"
              name="transportMethod"
              value={TRUCK}
              onChange={(e) => {
                onTransportMethodChange(e.value);
              }}
              checked={transportMethod === TRUCK}
            />
            <label htmlFor="transportMethod" className="ml-2 mr-2">
              {t("label.truck")}
            </label>

            <RadioButton
              inputId="transportMethod"
              name="transportMethod"
              value={RAIL}
              onChange={(e) => {
                onTransportMethodChange(e.value);
              }}
              checked={transportMethod === RAIL}
            />
            <label htmlFor="transportMethod" className="ml-2">
              {t("label.rail")}
            </label>
          </div>

          {transportMethod === RAIL ? (
            <div className="col-12 md:w-7">
              <span className="p-float-label">
                <InputText
                  value={wagonReferenceNumber}
                  onChange={(e) => {
                    setWagonReferenceNumber(e.target.value);
                    setErrorInWagonReferenceNumber({
                      error: false,
                      message: "",
                    });
                  }}
                  className={`w-full md:w-12 ${errorInWagonReferenceNumber.error ? "p-invalid" : ""
                    }`}
                />
                <label htmlFor="licensePlate">
                  {transportMethod === "TRUCK"
                    ? t("label.licensePlate")
                    : t("label.wagonReferneceNumber")}{" "}
                  <b className="text-primary">*</b>
                </label>
              </span>
              <span
                className="p-error block pt-1"
                style={{
                  visibility: `${errorInWagonReferenceNumber.error ? "" : "hidden"
                    }`,
                }}
              >
                {errorInWagonReferenceNumber.message}
              </span>
            </div>
          ) : (
            <>
              <div className="col-12 md:w-7">
                <span className="p-float-label">
                  <InputText
                    value={truckPlate}
                    onChange={(e) => {
                      setTruckPlate(e.target.value);
                      setErrorInTruckPlate({ error: false, message: "" });
                    }}
                    className={`w-full md:w-12 ${errorInTruckPlate.error ? "p-invalid" : ""
                      }`}
                  />
                  <label htmlFor="licensePlate">
                    {t("label.truckPlate")} <b className="text-primary">*</b>
                  </label>
                </span>
                <span
                  className="p-error block pt-1"
                  style={{
                    visibility: `${errorInTruckPlate.error ? "" : "hidden"}`,
                  }}
                >
                  {errorInTruckPlate.message}
                </span>
              </div>

              <div className="col-12 md:w-7">
                <span className="p-float-label">
                  <InputText
                    value={trailerPlate}
                    onChange={(e) => {
                      setTrailerPlate(e.target.value);
                      setErrorInTrailerPlate({ error: false, message: "" });
                    }}
                    className={`w-full md:w-12 ${errorInTrailerPlate.error ? "p-invalid" : ""
                      }`}
                  />
                  <label htmlFor="licensePlate">
                    {t("label.trailerPlate")} <b className="text-primary">*</b>
                  </label>
                </span>
                <span
                  className="p-error block pt-1"
                  style={{
                    visibility: `${errorInTrailerPlate.error ? "" : "hidden"}`,
                  }}
                >
                  {errorInTrailerPlate.message}
                </span>
              </div>
            </>
          )}

          <div className="col-12 md:w-7">
            <span className="p-float-label">
              <InputText
                value={carrierId}
                onChange={(e) => {
                  setCarrierId(e.target.value);
                  setErrorInCarrierId({ error: false, message: "" });
                }}
                className={`w-full md:w-12 ${errorInCarrierId.error ? "p-invalid" : ""
                  }`}
              />
              <label htmlFor="carrierId">
                {t("label.carrierId")} <b className="text-primary">*</b>
              </label>
            </span>
            <span
              className="p-error block pt-1"
              style={{
                visibility: `${errorInCarrierId.error ? "" : "hidden"}`,
              }}
            >
              {errorInCarrierId.message}
            </span>
          </div>

          <div className="col-12 md:w-7">
            <span className="p-float-label">
              <Dropdown
                inputId="truckingCompany"
                value={truckingCompany}
                onChange={(e) => {
                  setTruckingCompany(e.value);
                  setErrorInTruckingCompany({ error: false, message: "" });
                }}
                options={truckingCompanies}
                optionLabel="label"
                className={`w-full md:w-12 ${errorInTruckingCompany.error ? "p-invalid" : ""
                  }`}
                showClear
              />
              <label htmlFor="truckingCompany">
                {t("label.truckingCompany")} <b className="text-primary">*</b>
              </label>
            </span>
            <span
              className="p-error block pt-1"
              style={{
                visibility: `${errorInTruckingCompany.error ? "" : "hidden"}`,
              }}
            >
              {errorInTruckingCompany.message}
            </span>
          </div>
        </div>
      </Dialog>
    </>
  );
};
