import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_POST_UPDATE_FINAL_ARRIVAL } from '../../actions/ApiConstants';
import { convertToYYYYMMDDWithoutSlash, convertToHHmmss } from '../../util/Utility';
import { Toast } from 'primereact/toast';
import { postCallWithoutReducer } from '../../actions/performAction';
import { BANNER_LIFE_TIME } from '../../util/Constants';

export const UpdateFinalArrivalDialog = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useRef(null);
    const [finalArrivalDate, setFinalArrivalDate] = useState(new Date());
    const [finalArrivalTime, setFinalArrivalTime] = useState(new Date());

    const [errorInTime, setErrorInTime] = useState({
        error: false,
        message: "",
    });

    const onClickConfirm = () => {
        let flag = false;

        if (!finalArrivalDate || !finalArrivalTime) {
            setErrorInTime({
                error: true,
                message: "Please select both Arrival date and time."
            });
            return;
        }

        const combinedFinalArrivalDateTime = new Date(
            finalArrivalDate.getFullYear(),
            finalArrivalDate.getMonth(),
            finalArrivalDate.getDate(),
            finalArrivalTime.getHours(),
            finalArrivalTime.getMinutes(),
            finalArrivalTime.getSeconds()
        );

        let errorTransportId = [];
        Array.from(props.selectedFinalArrivalData.map((data) => {
            return {
                transportId: data.transportId,
                lcpTime: new Date(data.lastLoadCompleteTime)
            }

        }
        )).forEach((dto) => {
            if (combinedFinalArrivalDateTime < dto.lcpTime) {
                errorTransportId.push(dto.transportId);
                flag = true;
            }
        });

        if (flag) {
            setErrorInTime({
                error: true,
                message: "LCP time is greater than Arrival Time for TransportIds: \n" + errorTransportId.join(", \n")
            });
        }

        if (combinedFinalArrivalDateTime > new Date()) {
            setErrorInTime({
                error: true,
                message: "Arrival Date/Time should be less then or equal to current time."
            });
            flag = true;
        }

        if (!flag) {
            let finalArrivalDto = {
                subCompoundCode: props.selectedSubCompound,
                updateArrivalDto: {
                    arrivalDate: convertToYYYYMMDDWithoutSlash(finalArrivalDate),
                    arrivalTime: convertToHHmmss(finalArrivalTime),
                    transportIds: Array.from(props.selectedFinalArrivalData.map((data) => data.transportId))
                }
            }

            dispatch(
                postCallWithoutReducer(
                    API_POST_UPDATE_FINAL_ARRIVAL,
                    finalArrivalDto,
                    props.onSuccessSaveUpdateFinalArrival,
                    onFailure
                )
            );
        }
    }

    const onFailure = (error) => {
        toast.current.show({ severity: "error", summary: "Error", detail: error, life: BANNER_LIFE_TIME });
    };

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            {t("label.finalArrConfirmation")}
        </div>
    );

    const footerContent = (
        <div className='grid'>
            <div className='sm:col-12 md:col-8 text-primary'
                style={{ textAlign: 'left' }}
            >
                {t("message.timeZoneMessageForUpdateArrival")}
            </div>
            <div className='sm:col-12 md:col-4 flex justify-content-end' >
                <div>
                    <Button label={t("button.confirm")}
                        icon="pi pi-check" size='small'
                        onClick={onClickConfirm} />
                </div>
                <div>
                    <Button label={t("button.cancel")} icon="pi pi-times"
                        onClick={() => props.setUpdateFinalArrivalDialogVisible(false)} size='small' />
                </div>
            </div>
        </div>);

    return (
        <>
            <Toast ref={toast} position="top-center" />
            <Dialog
                visible={props.updateFinalArrivalDialogVisible}
                modal
                header={headerElement}
                footer={footerContent}
                onHide={() => props.setUpdateFinalArrivalDialogVisible(false)}
                closable
                breakpoints={{ '1000px': "60%", '800px': "70%", '667px': '25rem' }}
                style={{
                    height: '340px',
                    maxWidth: "550px",
                }}
            >
                <div className='grid justify-content-center gap-4 m-2 pt-1'>
                    <div className="col-12 md:w-7">
                        <span className="p-float-label">
                            <Calendar inputId="finalArrivalDate"
                                value={finalArrivalDate}
                                onChange={(e) => {
                                    setFinalArrivalDate(e.value)
                                    setErrorInTime({
                                        error: false,
                                        message: "",
                                    });
                                }}
                                maxDate={new Date()}
                                dateFormat='dd/mm/yy'
                                showIcon />
                            <label htmlFor="finalArrivalDate">{t("label.finalArrivalDate")}</label>
                        </span>
                    </div>
                    <div className="col-12 md:w-7">
                        <span className="p-float-label">
                            <Calendar inputId="time"
                                value={finalArrivalTime}
                                onChange={(e) => {
                                    setFinalArrivalTime(e.value)
                                    setErrorInTime({
                                        error: false,
                                        message: "",
                                    });
                                }}
                                maxDate={new Date()}
                                showIcon
                                timeOnly
                                dateFormat='hh:mm:ss'
                                icon={() => <i className='pi pi-clock' />} />
                            <label htmlFor="time">{t("label.finalArrivalTime")}</label>
                        </span>
                        <div
                            className="p-error block mt-2 w-full"
                            style={{
                                visibility: `${errorInTime.error ? "" : "hidden"}`,
                                maxHeight: '35px',
                                overflowY: 'auto'
                            }}
                        >
                            {errorInTime.message}
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};