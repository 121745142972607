import { useMsal } from "@azure/msal-react";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { useTranslation } from "react-i18next";
import {
  getShowLoginOptionComponent,
  setLoginMethod,
  setShowLoginOptionComponent,
} from "../../util/SessionUtils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LOGIN_METH_AZURE, LOGIN_METH_TARS } from "../../util/Constants";
import { loginWithTars } from "../../util/oidc/AuthServiceForTars";
import "./LoginOptionsComponent.css";

export const LoginOptionsComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { instance } = useMsal();

  useEffect(() => {
    window.addEventListener("error", (e) => {
      console.log("error", e);
      if (
        e.message.startsWith("ResizeObserver loop") ||
        e.message.startsWith("Frame window timed out")
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  const onClickLoginWithAzureAd = () => {
    setLoginMethod(LOGIN_METH_AZURE);
    const request = {
      scopes: ["user.read"],
      forceRefresh: true,
      refreshTokenExpirationOffsetSeconds: 1800,
    };
    instance.loginRedirect(request);
    setShowLoginOptionComponent(false);
  };

  const onClickLoginWithTars = () => {
    setLoginMethod(LOGIN_METH_TARS);
    loginWithTars();
    setShowLoginOptionComponent(false);
  };

  const createLabelForLoginWithAzureAd = () => {
    return (
      <>
        <div className="mb-3">
          {/* <span>{t("label.loginWithAzureAd")}</span> */}
          <h2>PAN-E Members</h2>
          <span>TME - Affiliates-TCEU-TMI Dealers</span>
        </div>
      </>
    );
  };

  const createLabelForLoginWithTars = () => {
    return (
      <>
        <div className="mb-3">
          {/* <span>{t("labl.loginWithAzureAd")}</span> */}
          <h2>TARS User</h2>
          <span>NON-Affiliates-Suppliers-Dealers</span>
        </div>
      </>
    );
  };

  return (
    <>
      {getShowLoginOptionComponent() === "true" ? (
        <div className="text-primary">
          <div className="flex justify-content-center">
            <div className="flex justify-content-center align-self-center border-1 border-primary border-round-2xl">
              <img
                src="message.jpg"
                alt="Login message"
                className="align-self-center m-3 responsive-message-image"
              />
            </div>
          </div>

          <div className="flex justify-content-center mt-3">
            <Fieldset
              legend={t("label.login")}
              style={{ height: "350px" }}
              className="responsive-fieldset"
            >
              <div className="flex justify-content-center">
                <h1 className="text-primary mb-4">
                  {t("message.selectLoginOption")}
                </h1>
              </div>

              <div className="flex flex-column gap-3">
                <Button
                  label={createLabelForLoginWithAzureAd()}
                  onClick={() => onClickLoginWithAzureAd()}
                  icon="pi pi-microsoft"
                  severity="primary"
                  className="h-5rem"
                />
                <Button
                  label={createLabelForLoginWithTars()}
                  onClick={() => onClickLoginWithTars()}
                  icon="pi pi-sign-in"
                  severity="primary"
                  className="h-5rem"
                />
              </div>
            </Fieldset>
          </div>
        </div>
      ) : (
        <div className="flex justify-content-center w-full">
          <div className="fixed text-primary" style={{ top: "45%" }}>
            <h1>Please wait...</h1>
          </div>
        </div>
      )}
    </>
  );
};
